import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules, PreloadingStrategy } from '@angular/router';
import { ComplaintComponent } from './complaint/complaint.component';

const routes: Routes = [{
  path: '', loadChildren: './complaint/complaint.module#ComplaintModule',
}];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
