import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContentAnimateDirective } from './_directives';
import { DeviceDetectorModule, DeviceDetectorService } from 'ngx-device-detector';
import { MsalModule } from '@azure/msal-angular';
import { OAuthSettings } from './_settings/oauth';
import { NgxSelectModule } from 'ngx-select-ex';

 
export function translateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    NgxMaskModule,
    AppRoutingModule,
    HttpClientModule,
    DeviceDetectorModule,
    NgxSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MsalModule.forRoot({
      clientID: OAuthSettings.appId,
      cacheLocation: "localStorage",
      popUp: true,
      // redirectUri: "https://portal.complaints.pgc.edu",
      // postLogoutRedirectUri: "https://portal.complaints.pgc.edu", 

    //  redirectUri: "http://complaintsportal.thetowertech.com",
    //  postLogoutRedirectUri: "http://complaintsportal.thetowertech.com",
    // redirectUri: "https://complaint.pgc.edu",
    // postLogoutRedirectUri: "https://complaint.pgc.edu", 
     redirectUri: "http://localhost:4200",
     postLogoutRedirectUri: "http://localhost:4200",

      navigateToLoginRequestUrl: false,
      // storeAuthStateInCookie: true
    }),
  ],
  providers:[DeviceDetectorService ],
  
  bootstrap: [AppComponent]
})
export class AppModule { }
