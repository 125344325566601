export class ComplaintRestriction {

  constructor(language, disclaimer, complaint) {
    this.language = language;
    this.disclaimer = disclaimer;
    this.complaint = complaint;
  }

  language: string;
  disclaimer: boolean;
  complaint: string;
}

export class TrackComplaintRestriction {

  constructor(language, trackComplaint, complaintDetails) {
    this.language = language;
    this.trackComplaint = trackComplaint;
    this.complaintDetails = complaintDetails;
  }


  language: string;
  trackComplaint?: boolean;
  complaintDetails?: boolean;
}
